.inventories {
  &__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3rem;
    @include media($sm) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__total {
    font-size: 3rem;
    text-transform: uppercase;
    color: $color-black;
    @include media($sm) {
      font-size: 2rem;
      margin-bottom: 2rem;
    }
  }
  &__sortBy {
    background: #F4F4F4;
    padding: 1rem 4rem 1rem 1rem;
    font-size: 1.6rem;
    font-weight: 500;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    outline: none;
    position: relative;
    background-image: url('../../assets/images/icon/arrow-down.svg');
    background-repeat: no-repeat;
    background-position: 95% center;
    cursor: pointer;
  }
  &__item {
    background: $color-white;
    box-shadow: 10px 10px 50px 3px rgba(39, 92, 141, 0.1);
    margin-bottom: 1rem;
    padding: 3rem 5rem;
    display: flex;
    @include media($lg) {
      padding: 2rem 3rem;
    }
    @include media($md) {
      display: flex;
      flex-direction: column;
    }
    @include media($sm) {
      padding: 2rem  1.5rem;
    }
    &_img {
      flex: 0 0 25rem;
      max-width: 25rem;
      height: 15rem;
      position: relative;
      margin-right: 4rem;
      overflow: hidden;
      cursor: pointer;
      @include media($md) {
        flex: 0 0 auto;
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 1.5rem;
        height: 17rem;
      }
      &:hover img {
        transform: scale(1.1);
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 1s ease-in-out;
      }
      span {
        position: absolute;
        right: 0;
        bottom: 0;
        background-color: $color-white;
        font-size: 2rem;
        font-weight: 700;
        padding: .2rem 1rem;
        border-radius: 2rem 0 0 0;
      }
      i {
        position: absolute;
        top: 0;
        right: 0;
        padding: .5rem;
        background-color: rgba($color-black, .3);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &_name {
      display: inline-block;
      font-size: 2.4rem;
      font-weight: 700;
      letter-spacing: -0.01em;
      color: $color-blue;
      position: relative;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }

    }
    &_desc {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.7;
      color: $color-grey;
      margin: 1rem 0 2rem;
    }
    &_contact {
      display: flex;
      align-items: center;
      margin-top: auto;
      @include media($xs) {
        align-items: flex-start;
        flex-direction: column;
      }
      &--item {
        display: flex;
        align-items: center;
        &:not(:last-of-type) {
          margin-right: 2rem;
          @include media($xs) {
            margin-right: 0;
            margin-bottom: 1.5rem;
          }
        }
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 2rem;
          }
          margin-right: 1rem;
        }
        a {
          text-decoration: none;
          margin: 0;
          font-size: 1.8rem;
          color: $color-black;
          position: relative;
          &:hover:before {
            opacity: 1;
          }
          &:before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            background-color: $color-black;
            opacity: 0;
            transition: opacity .3s ease-in-out;
          }
        }
      }
    }
  }

  .infinite-scroll {
    padding: 3rem 6rem 9rem;
    margin: -3rem -6rem -9rem;
    @include media($sm) {
      padding: 2rem;
      margin: -2rem;
    }
  }
}
