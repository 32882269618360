.headerVideo {
  position: relative;
  height: 60rem;
  @include media($lg) {
    height: auto;
  }
  &__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    video {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: center;
      object-position: center;
    }
  }
  &__wr {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
  }
  &__desc {
    h1 {
      font-weight: 700;
      font-size: 7.2rem;
      line-height: 1.2;
      color: $color-white;
      margin-top: 0;
      margin-bottom: 1.5rem;
      @include media($lg) {
        font-size: 6rem;
      }
    }
    p {
      font-weight: normal;
      font-size: 18px;
      line-height: 1.8;
      color: $color-white;
      margin-top: 0;
      margin-bottom: 2.5rem;
      span {
        display: inline-block;
        margin-left: .4rem;
        background-color: $color-white;
        color: $color-black;
        padding: 0 .6rem;
      }
    }
    a {
      max-width: 19rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__application {
    position: absolute;
    right: 3rem;
    bottom: 3rem;
    @include media($md)  {
      position: static;
      margin-bottom: 2rem;
    }
    &_wr {
      background-color: rgba($color-black, .3);
      padding: 1rem 2rem 3rem;
      text-align: right;

      @include media($md) {
        background-color: transparent;
        text-align: left;
        padding: 1rem 0;
      }
      p {
        text-transform: uppercase;
        font-size: 3rem;
        font-weight: 700;
        color: $color-white;
        margin: 0;
      }
       a {
        font-size: 1.6rem;
        font-weight: 500;
        color: $color-white;
      }
    }
    .btn {
      padding: .5rem 1.5rem;
      position: absolute;
      right: 2rem;
      bottom: -2rem;
      @include media($md) {
        position: static;
      }
      span {
        font-size: 2rem;
        text-align: center;
      }
    }
  }
}
