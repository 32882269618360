.confirmModal {
  .modal__content {
    align-items: center;
    max-width: 30rem;
  }
  &__wr {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    h4 {
      margin-top: 4rem;
      margin-bottom: 2rem;
      font-size: 2rem;
      font-weight: 700;
    }
    p {
      margin-top: 0;
      margin-bottom: 5rem;
      font-size: 1.8rem;
      font-weight: 400;
    }
    .btn {
      padding: 1rem 5rem;
    }
  }
}
