.benefits {
  position: relative;
  margin-bottom: 15rem;
  @include media($sm) {
    margin-bottom: 10px;
  }
  .pin-spacer {
    height: auto !important;
  }
  .black {
    background: $color-black;
    clip-path: polygon(0 15%, 100% 0, 100% 85%, 0% 100%);
    color: $color-white;

    @include media($md) {
      clip-path: polygon(0 10%, 100% 0, 100% 90%, 0% 100%);
    }
  }
  &__wrapper {
    display: flex;
    align-items: flex-start;
    position: relative;
    padding: 12rem 0;
    @include media($xl) {
      padding: 8rem 0;
    }
    @include media($md) {
      padding: 6rem 0;
    }
    &:nth-child(3n) {
      padding-bottom: 0;
    }
  }
  &__block {
    max-width: 40rem;
    flex: 0 0 40rem;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    text-align: right;
    position: relative;
    @include media($lg) {
      max-width: 30rem;
      flex: 0 0 30rem;
    }
    @include media($md) {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }
  &__icon {
    width: 6.5rem;
    height: 6.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #D6D6D6;
    margin-bottom: 1rem;
  }
  &__title {
    font-size: 4rem;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 1.5rem;
  }
  &__desc {
    font-size: 1.6rem;
    line-height: 2;
    @include media($sm) {
      font-size: 1.8rem;
    }
  }
  &__car {
    position: absolute;
    top: 0;
    left: auto;
    right: 0;
    max-width: 120rem !important;
    @include media($xl) {
      max-width: 60rem !important;
    }
    @include media($lg) {
      max-width: 50rem !important;
    }
    @include media($md) {
      display: none;
    }
    &--none {
      opacity: 0;
      height: 1px;
      width: 1px;
      position: absolute;
      visibility: hidden;
      pointer-events: none;
      @include media($md) {
        display: none;
      }
    }
  }
}
