.social {
  margin-bottom: 13rem;
  @include media($sm) {
    margin-bottom: 4rem;
  }
  &__wr {
    display: flex;
    justify-content: space-between;
    background: $color-white;
    box-shadow: 10px 10px 50px 3px rgba(39, 92, 141, 0.1);
    overflow: hidden;
    @include media($lg) {
      flex-direction: column-reverse;
    }
  }
  &__content {
    padding: 5rem 3rem 5rem 4rem;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    @include media($sm) {
      align-items: center;
      padding: 2rem 0 3rem;
    }
    h4 {
      font-size: 3.5rem;
      font-weight: 700;
      margin: 0;
      color: $color-black;
      @include media($sm) {
        text-align: center;
        font-size: 3.5rem;
      }
    }
    p {
      font-size: 1.8rem;
      font-weight: 400;
      line-height: 1.5;
      margin: 1.5rem 0 4rem;
      @include media($sm) {
        text-align: center;
        font-size: 1.6rem;
      }
    }
  }
  &__btns {
    display: grid;
    grid-template-columns: 14rem 14rem 14rem;
    grid-column-gap: .5rem;

    @include media($sm) {
      grid-template-columns: 14rem;
      width: 100%;
      justify-content: center;
      grid-row-gap: 1rem;
    }
  }
  &__btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 2rem;
    text-decoration: none;
    color: $color-black;
    border: 1px solid #D6D6D6;
    &:hover {
      border-color: $color-black;
    }
    span {
      font-size: 1.5rem;
      line-height: 1;
    }
  }
  &__images {
    display: flex;
    @include media($lg) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
    &_img {
      width: 20rem;
      height: 100%;
      @include media($xl) {
        width: 16rem;
      }
      @include media($lg) {
        transform: translateX(0);
        clip-path: none;
        width: auto;
        height: 25rem;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &-1 {
        clip-path: polygon(10% 0, 100% 0%, 90% 100%, 0% 100%);
        transform: translateX(6rem);
        width: 22rem;
        @include media($xl) {
          width: 16rem;
        }
        @include media($lg) {
          transform: translateX(0);
          clip-path: none;
          width: auto;
          height: 25rem;
        }
      }
      &-2 {
        clip-path: polygon(10% 0, 100% 0%, 90% 100%, 0% 100%);
        transform: translateX(3rem);
        width: 22rem;
        @include media($xl) {
          width: 16rem;
        }
        @include media($lg) {
          transform: translateX(0);
          clip-path: none;
          width: auto;
          height: 25rem;
        }
      }
    }
  }
}
