.modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba($color-black, .3);
    border: none;
    padding: 0;
    margin: 0;
  }
  &__cross {
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 2rem;
    right: 2rem;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
      width: 2rem;
      height: 1px;
      background-color: $color-black;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
  &__top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 3.5rem;
  }
  &__label {
    font-size: 2rem;
    font-weight: 700;
    margin: 0;
  }
  &__content {
    position: relative;
    top: 10rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    background-color: $color-white;
    max-width: 78rem;
    width: 100%;
    padding: 2rem;
    @include media($lg) {
      max-width: fit-content;
    }
  }
}

@import "ConfirmModal";
