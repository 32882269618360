.carousel {
  &__main {
    position: relative;
    margin-bottom: 15px;
    &_image {
      cursor: pointer;
      height: 48rem;
      width: 40rem;
      background-color: rgba($color-black, .05);
      display: flex !important;
      align-items: center;
      justify-content: center;
      .loader {
        margin: auto !important;
      }
      @include media($md) {
        height: 30rem;
      }
      @include media($sm) {
        height: 22rem;
      }
      &:hover img {
        transform: scale(1.03);
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all .5s ease;
      }
    }
  }
  &__thumb {
    margin: 0 1rem;
    cursor: pointer;
    overflow: hidden;
    height: 7.5rem;
    @include media($sm) {
      height: 5rem;
    }
    &:hover img {
      transform: scale(1.03);
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 1s ease;
    }
  }
  &__arrow {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 3;
    &:before {
      display: none;
    }
    &--left {
      left: 2rem;
      right: auto;
      z-index: 3;
    }
    &--right {
      left: auto;
      right: 2rem;
      z-index: 3;
    }
  }
}
