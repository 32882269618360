.contact {
  margin-top: 7rem;
  @include media($md) {
    margin-top: 3rem;
  }
  &__map {
    height: 30rem;
    width: 100%;
    margin-bottom: 2rem;
  }
  &__wr {
    display: flex;
    justify-content: center;
    margin-bottom: 5rem;
    @include media($md) {
      flex-direction: column-reverse;
    }
  }
  &__title {
    font-weight: 700;
    font-size: 3rem;
    line-height: 1.2;
    font-feature-settings: 'liga' off;
    color: $color-black;
    margin: 0;
  }
  &__form {
    max-width: 40rem;
    flex: 0 0 40rem;
    background: $color-white;
    box-shadow: 10px 10px 50px 3px rgba(39, 92, 141, 0.1);
    padding: 4rem 3.5rem;
    margin-right: 3rem;
    @include media($md) {
      flex: 0 0 100%;
      max-width: 100%;
      margin-right: 0;
      padding: 2.5rem  1.5rem;
    }
    .contact__title {
      margin-bottom: 2.5rem;
    }
    form {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1.5rem 1rem;
      @include media($md) {
        display: flex;
        flex-direction: column;
      }
      .field {
        &--fullName { grid-area: 1 / 1 / 2 / 3; }
        &--email { grid-area: 2 / 1 / 3 / 2; }
        &--phone { grid-area: 2 / 2 / 3 / 3; }
        &--message { grid-area: 3 / 1 / 4 / 3; }
      }
      .btn {
        grid-area: 4 / 1 / 5 / 3;
        padding: 1rem;
      }
    }
  }
  &__description {
    padding-top: 2rem;
    @include media($md) {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 3rem;
      padding-top: 0;
    }
    .contact__title {
      margin-bottom: 3rem;
    }
    &_item {
      display: flex;
      align-items: center;
      &:not(:last-of-type) {
        margin-bottom: 2.5rem;
      }
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 2rem;
        @include media($md) {
          margin-right: 1.5rem;
        }
        svg {
          width: 2rem;
        }
      }
      a, p {
        text-decoration: none;
        margin: 0;
        font-size: 1.8rem;
        color: $color-black;
      }
      a {
        position: relative;
        &:hover:before {
          opacity: 1;
        }
        &:before {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 1px;
          background-color: $color-black;
          opacity: 0;
          transition: opacity .3s ease-in-out;
        }
      }
    }
  }
  .social {
    margin-bottom: 5rem;
  }
}
