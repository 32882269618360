.field {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  &__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: .5rem;
    width: 100%;
  }
  &__text {
    font-size: 1.4rem;
    font-weight: 400;
    color: $color-black;
  }
  &__error {
    color: #C74C4D;
  }
  &__input {
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 1.4rem;
    font-weight: 400;
    padding: 1rem 1.5rem;
    color: $color-black;
    outline: none;
    width: 100%;
    &:hover {
      box-shadow: 0 0 0 rgba(0, 0, 0, 0.25);
      border-color: #979797;
    }
    &:active {
      border-color: $color-black;
    }
    &::placeholder {
      color: #CACACB;
    }
    &.error {
      color: #C74C4D;
      border-color: #C74C4D;
    }
  }
  &__textarea {
    resize: none;
    height: 13rem;
  }
}

.dataPicker {
  .react-datepicker-wrapper {
    width: 100%;
  }
}
