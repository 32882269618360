.nav {
  padding: 1.5rem 0;
  position: relative;
  &__wr {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__menu {
    display: flex;
    align-items: center;
    @include media($md) {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 100;
      height: 100vh;
      width: 100vw;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background-color: $color-white;
      padding-top: 3rem;
      transform: translateX(-100%);
      opacity: 0;
      visibility: hidden;
      transition: all .3s ease-in-out;
    }
    &.on {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;
    }
  }
  &__list {
    display: flex;
    align-items: center;
    margin-right: 4rem;
    @include media($md) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__item {
    &:not(:last-of-type) {
      margin-right: 3rem;

      @include media($lg) {
        margin-right: 1rem;
      }

      @include media($md) {
        margin-right: 0;
        margin-bottom: 2rem;
      }
    }
  }
  &__link {
    transform: skew(15deg);
    display: block;
    padding: 1rem 2rem;
    text-decoration: none;
    transition: all .3s ease-in-out;
    @include media($lg) {
      padding: .6rem 1rem;
    }
    @include media($md) {
      transform: skew(0);
      padding: 1rem 4rem 1rem 2rem ;
      clip-path: polygon(0 0, 85% 0, 100% 100%, 0% 100%);
    }
    &:hover {
      background-color: #F4F4F4;
    }
    &.isActive {
      background-color: $color-black;
      span {
        color: $color-white;
      }
    }
    span {
      display: block;
      transition: all .3s ease-in-out;
      transform: skew(-15deg);
      color: $color-black;
      font-size: 1.4rem;
      font-weight: 400;
      @include media($md) {
        transform: skew(0);
        font-size: 2rem;
      }
    }
  }
  &__login {
    padding: .7rem 2rem;
    display: flex;
    align-items: center;
    @include media($lg) {
      padding: .6rem 1rem;
    }
    @include media($md) {
      margin-top: 2rem;
      margin-left: 1.5rem;
    }
    span {
      font-size: 1.4rem;
      font-weight: 400;
      @include media($md) {
        font-size: 2rem;
      }
    }
    svg {
      margin-right: 1rem;
    }
    &:focus,
    &:active {
      svg path {
        fill: $color-black ;
      }
    }
  }
  &__toggle {
    display: flex;
    flex-direction: column;
    width: 4rem;
    height: 4rem;
    cursor: pointer;
    border: 1px solid $color-black;
    padding: 8px 6px;
    @media only screen and (min-width: 767px) {
      display: none;
    }
    span:after,
    span:before {
      content: "";
      position: absolute;
      left: 0;
      top: 9px;
    }
    span:after {
      top: 18px;
    }
    span {
      position: relative;
      display: block;
    }
    span,
    span:after,
    span:before {
      width: 100%;
      height: 2px;
      background-color: $color-black;
      transition: all 0.3s;
      backface-visibility: hidden;
      border-radius: 2px;
    }
    &.on span {
      background-color: transparent;
    }
    &.on span:before {
      transform: rotate(45deg) translate(-1px, 0px);
    }
    &.on span:after {
      transform: rotate(-45deg) translate(6px, -7px);
    }
  }
}


