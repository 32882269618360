.inventory {
  margin-bottom: 10rem;
  .carousel {
    flex: 0 0 45%;
    max-width: 45%;
    @include media($lg) {
      flex: 0 0 55%;
      max-width: 55%;
    }
    @include media($md) {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 3rem;
    }
  }
  &__top {
    display: flex;
    justify-content: space-between;
    @include media($md) {
      flex-direction: column;
    }
  }
  &__title {
    margin: 0;
    font-size: 2.4rem;
    font-weight: 700;
    letter-spacing: -0.01em;
  }
  &__info-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
  }
  &__price {
    margin-right: 3rem;
    font-size: 2.8rem;
    line-height: 1.4;
    color: #8F96A2;
    @include media($md) {
      margin-right: 0;
    }
  }
  &__desc {
    flex: 0 0 53%;
    max-width: 53%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include media($lg) {
      flex: 0 0 42%;
      max-width: 42%;
    }

    @include media($md) {
      flex: 0 0 100%;
      max-width: 100%;
    }
    p {
      margin-top: 0;
      margin-bottom: 1.6rem;
      font-size: 1.4rem;
      line-height: 1.7;
      color: #8F96A2;
    }
    &_list {
      li {
        display: flex;
        align-items: center;
        &:not(:last-of-type) {
          margin-bottom: 1rem;
        }
        strong {
          font-size: 1.6rem;
          line-height: 1.7;
          color: $color-black;
          width: 10rem;
          margin-right: 2rem;
          display: block;
        }
      }
    }
    &_info {
      align-self: flex-start;
      margin-top: auto;
      background: #F4F4F4;
      padding: 1rem;
      @include media($md) {
        margin-top: 2rem;
      }
      &--item {
        display: flex;
        align-items: center;
        strong {
          font-size: 1.6rem;
          font-weight: 700;
          line-height: 1.7;
          color: $color-black;
          width: 7rem;
          margin-right: 2rem;
        }
        a {
          display: flex;
          align-items: center;
          text-decoration: none;
          &:hover span {
            text-decoration: underline;
          }
          svg {
            width: 2rem;
            margin-right: .5rem;
          }
          span {
            width: auto;
            color: $color-black;
          }
        }
      }
    }
  }

  &__info {
    background: #FFFFFF;
    box-shadow: 10px 10px 50px rgba(39, 92, 141, 0.1);
    padding: 3rem 6rem;
    margin-top: 3rem;
    @include media($md) {
      padding: 2rem 1.5rem;
    }
    h4 {
      margin: 0;
      color: rgba($color-black, .5);
      text-transform: uppercase;
      font-size: 1.6rem;
      font-weight: 700;
    }
    &_list {
      margin-top: 2rem;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 1.5rem;
      @include media($xl) {
        grid-template-columns: repeat(3, 1fr);
      }
      @include media($lg) {
        grid-template-columns: repeat(2, 1fr);
      }
      @include media($sm) {
        grid-template-columns: 1fr;
      }
      li {
        display: flex;
        align-items: center;
        strong {
          font-size: 1.6rem;
          line-height: 1.7;
          color: $color-black;
          display: block;
          margin-right: 1.5rem;
        }
      }
    }
  }


  .goBack {
    background-color: transparent;
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    font-weight: 400;
    margin: 3rem 0;
    outline: none;
    border: none;
    cursor: pointer;
    &:hover svg {
      transform: translateX(-.5rem);
    }
    svg {
      margin-right: 1rem;
      width: 1rem;
      transition: transform .3s ease-in-out;
    }
  }
  .loader {
    margin-top: 10rem;
  }
}
