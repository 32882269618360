.header {
  height: 50rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  position: relative;
  @include media($xl) {
    height: 40rem;
  }
  @include media($sm) {
    height: 22rem;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  }
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: absolute;
    top: 25%;
    @include media($sm) {
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &__title {
    text-align:center;
    z-index: 1;
    font-weight: 700;
    font-size: 7.2rem;
    line-height: 1;
    text-shadow: -2px 4px 45px rgba(0, 0, 0, 0.13);
    margin: 0;
    @include media($md) {
      font-size: 6rem;
    }
    @include media($sm) {
      font-size: 3rem;
    }
    &:first-of-type {
      color: $color-white;
    }
    &--blend {
      margin-left: .5rem;
      display: block;
      padding: 0 1rem;
      background-color: $color-white;
      mix-blend-mode: screen;
    }
  }
}
