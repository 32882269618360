.positions {
  &__layout {
    padding-top: 5rem;
    @include media($sm) {
      padding-top: 2.5rem;
    }
  }
  &__title {
    font-size: 3rem;
    text-align: center;
    margin-bottom: 5rem;
    text-transform: uppercase;
    @include media($sm) {
      font-size: 2rem;
      margin-bottom: 2.5rem;
    }
  }
  .infinite-scroll {
    padding: 3rem 6rem 9rem;
    margin: -3rem -6rem -9rem;
    @include media($sm) {
      padding: 2rem;
      margin: -2rem;
    }
  }
}

.position {
  background: $color-white;
  box-shadow: 10px 10px 50px 3px rgba(39, 92, 141, 0.1);
  margin-bottom: 1rem;
  padding: 3rem 5rem;
  transition: box-shadow .3s ease-in-out;
  cursor: pointer;
  @include media($md) {
    padding: 2rem 3rem ;
  }
  &:hover {
    box-shadow: 10px 21px 50px 3px rgba(39, 92, 141, 0.2);
    .position__title:before {
      opacity: 1;
    }
  }
  &__title {
    display: inline-block;
    font-size: 2.4rem;
    font-weight: 700;
    letter-spacing: -0.01em;
    color: $color-blue;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      background-color: $color-blue;
      opacity: 0;
      transition: opacity .4s ease-in-out;
    }
  }
  &__desc {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.7;
    color: $color-grey;
    margin: 1rem 0 2rem;
  }
  &__options {
    display: flex;
    align-items: flex-start;
    @include media($md) {
      flex-direction: column;
    }
  }
  &__option {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &:not(:last-of-type) {
      margin-right: 5rem;
      @include media($md) {
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }
  }
  &__item {
    &:not(:last-of-type) {
      margin-bottom: 2rem;
      @include media($md) {
        margin-bottom: 1rem;
      }
    }
    strong,
    span {
      font-size: 1.4rem;
      font-weight: 400;
      letter-spacing: -0.01em;
      color: $color-black;
    }
    strong {
      font-weight: 700;
      width: 8rem;
      margin-right: 2rem;
      display: inline-block;
      @include media($md) {
      }
    }
  }
}

.positionForm {
  &__wr {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 2rem;
    @include media($sm) {
      grid-template-columns: 1fr;
    }
  }
  &__info {
    font-size: 2.4rem;
    font-weight: 700;
    letter-spacing: -0.01em;
    color: $color-black;
    margin-bottom: 3rem;
    @include media($sm) {
      display: none;
    }
    span {
      color: $color-blue;
    }
  }
  &__label {
    font-weight: 700;
    font-size: 2rem;
    margin-top: 0;
    margin-bottom: 1.5rem;
  }
  &__block {
    padding-bottom: 3rem;
    padding-left: 1.5rem;
    @include media($sm) {
      padding-left: 0;
    }
    &:not(:last-of-type) {
      padding-right: 1.5rem;
      padding-left: 0;
      border-right: 1px solid #E0E0E0;
      @include media($sm) {
        padding-right: 0;
        border: none;
      }

    }
    &--small {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 1rem;
    }
    .field {
      &:not(:last-of-type) {
        margin-bottom: 1.5rem;
      }
    }
  }
  .btn {
    display: block;
    margin: 0 auto;
    padding: 1rem 6rem;
    span {
      text-transform: uppercase;
    }
  }
}
