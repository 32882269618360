.join-team {
  position: relative;
  &__wr {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10rem;
    align-items: center;
    @include media($sm) {
      grid-template: 1fr / 1fr;
      grid-row-gap: 3rem;
    }

  }
  &__content {
    h4 {
      margin: 0;
      font-size: 4rem;
      line-height: 1.3;
    }
    p {
      margin-top: .5rem;
      margin-bottom: 3rem;
      font-size: 1.8rem;
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 19rem;
    }
  }
}
