.footer {
  margin-top: auto;
  background-color: $color-black;
  padding: 3rem 0;
  &__wr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include media($xs) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__logo {
    svg  path {
      fill: $color-white;
    }
  }
  &__left {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    @include media($xs) {
      margin-bottom: 2.5rem;
    }
  }
  &__copyright {
    margin-top: 2rem;
    @include media($xs) {
      margin-top: 1rem;
    }
    span {
      color: $color-white;
      font-size: 1.2rem;
    }
  }
  &__right {
    display: flex;
  }
  &__block {
    &:first-of-type {
      margin-right: 10rem;
    }
    &_title {
      font-size: 2rem;
      font-weight: 700;
      color: $color-white;
      margin-top: 0;
      margin-bottom: 2rem;
    }
    &--menu {
      @include media($md) {
        display: none;
      }
    }
  }
  &__menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    li {
      &:not(:last-of-type) {
        margin-bottom: 1.5rem;
      }
    }
    a {
      color: $color-white;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__email {
    color: $color-white;
    font-size: 1.4rem;
    font-weight: 400;
    text-decoration: none;
    margin-bottom: 3rem;
    display: block;
    &:hover {
      text-decoration: underline;
    }
  }
  &__social {
    display: flex;
    align-items: center;
    li:not(:last-of-type) {
      margin-right: 1.5rem;
    }
    a {
      width: 3rem;
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba($color-white, .1);
      border-radius: 50%;
      transition: background-color .3s ease-in-out;
      &:hover {
        background-color: $color-white;
        svg path {
          fill: $color-black;
        }
      }
      svg {
        width: 1.7rem;
        path {
          fill: $color-white;
          transition: fill .3s ease-in-out;
        }
      }
    }
  }
}
