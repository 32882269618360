.services {
  &__block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include media($md) {
      flex-direction: column;
      margin-bottom: 4rem;
    }
    &:nth-of-type(2n) {
      flex-direction: row-reverse;
      @include media($md) {
        flex-direction: column;
      }
      .services__content {
        padding-right: 0;
      }
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 45%;
    flex: 0 0 45%;
    width: 100%;
    @include media($md) {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 3rem;
    }
    h4 {
      font-size: 4rem;
      font-weight: 700;
      color: $color-black;
      margin: 0;
      @include media($sm) {
        font-size: 3rem;
      }
    }
    p {
      font-size: 1.8rem;
      font-weight: 400;
      line-height: 1.7;
      margin-top: 2rem;
      margin-bottom: 0;
      @include media($sm) {
        font-size: 1.6rem;
      }
    }
  }
  &__img {
    flex: 0 0 50%;
    max-width: 50%;
    height: 50rem;
    @include media($lg) {
      height: 40rem;
    }
    @include media($md) {
      flex: 0 0 40rem;
      max-width: 40rem;
    }
    @include media($xs) {
      flex: 0 0 28rem;
      max-width: 28rem;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__decor {
    display: block;
    position: relative;
    margin: 5rem auto;
    width: 1.2rem;
    height: 1.2rem;
    background-color: #D6D6D6;
    border-radius: 50%;
    @include media($md) {
      display: none;
    }
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 5rem;
      height: 1px;
      background-color: #D6D6D6;
    }
    &:before {
      left: 2rem;
    }
    &:after {
      right: 2rem;
    }
  }
}
