$font-primary: 'Roboto Condensed', sans-serif;

$fw-300: 300;
$fw-400: 400;
$fw-700: 700;

$color-black: #000;
$color-white: #fff;
$color-blue: #0E6FFF;
$color-grey: #8F96A1;

$xxl: 1400;
$xl: 1200;
$lg: 992;
$md: 768;
$sm: 576;
$xs: 426;

@mixin media($width) {
  @media only screen and (max-width: #{$width - 1px}) {
    @content;
  }
}


.btn {
  border: 1px solid rgba($color-white, 0);
  text-decoration: none;
  cursor: pointer;
  outline: none;
  display: inline-block;
  vertical-align: middle;
  line-height: 1.4;
  padding: 2rem 1.6rem;
  transition: all .3s ease-in-out;
  span {
    display: block;
    font-size: 1.6rem;
    font-weight: 700;
    transition: all .3s ease-in-out;
  }
  &:focus,
  &:active,
  &:hover {
    transform: skew(15deg);
    span {
      transform: skew(-15deg);
    }
  }
  &--white {
    background-color: $color-white;
    span {
      color: $color-black;
    }
    &:focus,
    &:active {
      background-color: $color-black;
      span {
        color: $color-white;
      }
    }
  }
  &--black {
    background-color: $color-black;
    color: $color-black;
    span {
      color: $color-white;
    }
    &:focus,
    &:active {
      background-color: $color-white;
      border-color: $color-black;
      span {
        color: $color-black ;
      }
    }
  }
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

*::-webkit-input-placeholder {
  opacity: 1;
}

*:-moz-placeholder {
  opacity: 1;
}

*::-moz-placeholder {
  opacity: 1;
}

*:-ms-input-placeholder {
  opacity: 1;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  vertical-align: top;
}

html {
  font-size: 10px;
}

body {
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
  min-width: 320px;
  position: relative;
  line-height: 1.3;
  font-family: $font-primary;
  overflow-x: hidden;
  color: #000;
  font-weight: $fw-400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button,
input,
optgroup,
select,
textarea {
  font-family: $font-primary;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

.container {
  max-width: 117rem;
  width: 100%;
  padding: 0 1.5rem;
  margin: 0 auto;
}
.layout {
  background: $color-white;
  box-shadow: 10px 10px 50px 3px rgba(39, 92, 141, 0.1);
  padding: 9rem 6rem;
  transform: translateY(-15rem);
  @include media($lg) {
    padding: 6rem 3rem;
  }
  @include media($sm) {
    transform: translateY(0);
    padding: 4rem 1.5rem;
  }
}

@import "common/Input";
@import "common/Arrow";

@import "components/Nav";
@import "components/Footer";
@import "components/HeaderVideo";
@import "components/Benefits";
@import "components/JoinTeam";
@import "components/HomeServices";
@import "components/Social";
@import "components/Header";
@import "components/ServicesBlock";
@import "components/Positions";
@import "components/Loader";
@import "components/Modal";
@import "components/Contact";
@import "components/Inventories";
@import "components/Carousel";
@import "components/Inventory";
