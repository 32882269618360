.home {
  &-services {
    background: #F4F4F4;
    clip-path: polygon(0 15%, 100% 0, 100% 85%, 0% 100%);
    @include media($md) {
      clip-path: polygon(0 10%, 100% 0, 100% 90%, 0% 100%);
      margin-top: 4rem;
    }
    @include media($sm) {
      clip-path: polygon(0 5%, 100% 0, 100% 95%, 0% 100%);
      margin-top: 4rem;
    }
    &__wr {
      padding: 12rem 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include media($md) {
        flex-direction: column;
        align-items: flex-start;
        padding: 8rem 0;
      }
    }
    &__left {
      h2 {
        font-size: 4.8rem;
        font-weight: 700;
        color: $color-black;
        margin: 0;
      }
      p {
        font-size: 1.8rem;
        line-height: 2;
        color: $color-black;
        margin-top: 15px;
        margin-bottom: 30px;
        max-width: 48rem;
        width: 100%;
      }
      .btn {
        box-shadow: -2px 4px 45px rgba(0, 0, 0, 0.13)
      }
    }
    &__right {
      display: grid;
      grid-template: 1fr 1fr / 1fr 1fr;
      grid-gap: 4rem 9rem;
      @include media($md) {
        margin-top: 5rem;
      }
      @include media($xs) {
        grid-template-columns: 1fr 1fr;
        grid-gap: 2rem 4rem;
      }
    }
    &__item {
      display: flex;
      align-items: center;
    }
    &__icon {
      margin-right: 2rem;
    }
    &__content {
      display: flex;
      flex-direction: column;
      &_title {
        font-size: 2.8rem;
        font-weight: 700;
        margin-bottom: .5rem;
      }
      &__text {
        font-size: 1.6rem;
        font-weight: 400;
      }
    }
  }
  &-desc {
    margin: 7rem 0;
    &__wr {
      display: flex;
      flex-direction: column;
      align-items: center;
      h2 {
        font-size: 4.8rem;
        font-weight: 700;
        line-height: 1.4;
        color: $color-black;
        margin: 3.5rem 0;
        text-align: center;
        @include media($md) {
          font-size: 3.6rem;
        }
      }
    }
  }
}
